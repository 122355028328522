import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
    IInteraction,
    CHANNEL_TYPES,
    INTERACTION_STATES,
    INTERACTION_DIRECTION_TYPES,
    IField,
    RecordItem,
    setPresence
} from '@amc-technology/davinci-api';

import { PhoneService } from '../Services/phone.service';

import * as PMMessage from '../phone/phone.message';

@Injectable({
    providedIn: 'root'
})
export class InteractionFormatService {
    newInteraction: boolean;
    stringId: string;
    private incomingInteraction = new Subject();
    private interaction$ = this.incomingInteraction.asObservable();
    private outgoingInteraction = new Subject();
    private outeraction$ = this.outgoingInteraction.asObservable();

    constructor(
        private phoneService: PhoneService,
        private logger: LoggerService
    ) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : constructor : START'
            );

            this.newInteraction = false;
            this.stringId = '';

            this.incomingInteraction.subscribe((data: any) => {
                if (
                    data.data.category.includes(
                        'disconnect',
                        'deallocate',
                        'blindTransfer',
                        'consultTransfer'
                    )
                ) {
                    this.newInteraction = false;
                    this.stringId = '';
                }
                if (
                    data.data.category === 'disconnect' ||
          data.data.category.includes('Transfer')
                ) {
                    this.generateDeallocateMessage(data);
                } else {
                    this.generateMessage(data);
                }
            });

            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : constructor : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : interaction-format : constructor : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    public get interactions() {
        return this.interaction$;
    }

    public get outeractions() {
        return this.outeraction$;
    }

    public addInteraction(data: any) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : addInteraction : START'
            );

            this.incomingInteraction.next(data);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : addInteraction : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : interaction-format : addInteraction : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    public addOuteraction(data: IInteraction) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : addOuteraction : START'
            );

            this.outgoingInteraction.next(data);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : addOuteraction : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : interaction-format : addOuteraction : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private generateMessage(data: any) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : generateMessage : START'
            );

            const messageCategory = data.data.category;
            let path: any;
            let channel: CHANNEL_TYPES;
            let interactionState: INTERACTION_STATES;
            let interactionDirection: INTERACTION_DIRECTION_TYPES;
            let interactionValue: string;

            if (data.data.category === 'change') {
                path = data.data.interaction.new;
            } else {
                path = data.data.interaction;
            }

            if (path.isChat) {
                channel = CHANNEL_TYPES.Chat;
            } else if (path.isEmail) {
                channel = CHANNEL_TYPES.Email;
            } else if (path.isMessage) {
                channel = CHANNEL_TYPES.SMS;
            } else {
                channel = CHANNEL_TYPES.Telephony;
            }

            switch (path.state) {
                case 'CONNECTED':
                    interactionState = INTERACTION_STATES.Connected;
                    break;
                case 'DISCONNECTED':
                    interactionState = INTERACTION_STATES.Disconnected;
                    break;
                case 'DIALING':
                    interactionState = INTERACTION_STATES.Alerting;
                    break;
                case 'CONTACTING':
                    interactionState = INTERACTION_STATES.Initiated;
                    break;
                case 'HELD':
                    interactionState = INTERACTION_STATES.OnHold;
                    break;
                case 'ALERTING':
                    interactionState = INTERACTION_STATES.Alerting;
                    break;
                default:
                    interactionState = INTERACTION_STATES.Disconnected;
            }

            switch (path.direction) {
                case 'Inbound':
                    if (path.isCallback) {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Outbound;
                        break;
                    } else {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Inbound;
                        break;
                    }
                case 'Outbound':
                    interactionDirection = INTERACTION_DIRECTION_TYPES.Outbound;
                    break;
                case 'Internal':
                    if (path.isCallback) {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Outbound;
                        break;
                    } else {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Internal;
                        break;
                    }
                default:
                    interactionDirection = INTERACTION_DIRECTION_TYPES.Internal;
            }

            const message: PMMessage.IPMInteractionStateChangeMessage = {
                category: messageCategory,
                type: data.type,
                interaction: {
                    devName: path.name,
                    interactionID: path.id,
                    cType: channel,
                    dType: interactionDirection,
                    iState: interactionState
                }
            };
            this.createCRMInteraction(message, path);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : generateMessage : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : interaction-format : generateMessage : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private generateDeallocateMessage(data: any) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : generateDeallocateMessage : START'
            );

            const messageCategory = 'deallocate';
            const path = data.data.interaction;
            let channel: CHANNEL_TYPES;
            const interactionState: INTERACTION_STATES =
        INTERACTION_STATES.Disconnected;
            let interactionDirection: INTERACTION_DIRECTION_TYPES;

            if (path.isChat) {
                channel = CHANNEL_TYPES.Chat;
            } else if (path.isEmail) {
                channel = CHANNEL_TYPES.Email;
            } else if (path.isMessage) {
                channel = CHANNEL_TYPES.SMS;
            } else {
                channel = CHANNEL_TYPES.Telephony;
            }

            switch (path.direction) {
                case 'Inbound':
                    if (path.isCallback) {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Outbound;
                        break;
                    } else {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Inbound;
                        break;
                    }
                case 'Outbound':
                    interactionDirection = INTERACTION_DIRECTION_TYPES.Outbound;
                    break;
                case 'Internal':
                    if (path.isCallback) {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Outbound;
                        break;
                    } else {
                        interactionDirection = INTERACTION_DIRECTION_TYPES.Internal;
                    }
                    break;
                default:
                    interactionDirection = INTERACTION_DIRECTION_TYPES.Internal;
            }

            const interactionValue = path.displayAddress;

            const message: PMMessage.IPMInteractionStateChangeMessage = {
                category: messageCategory,
                type: data.type,
                interaction: {
                    devName: path.name,
                    interactionID: path.id,
                    cType: channel,
                    dType: interactionDirection,
                    iState: interactionState
                }
            };
            this.createCRMInteraction(message, path);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : generateDeallocateMessage : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : interaction-format : generateDeallocateMessage : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // eslint-disable-next-line max-statements
    private async createCRMInteraction(
        message: PMMessage.IPMInteractionStateChangeMessage,
        cadData: any
    ) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : createCRMInteraction : START'
            );

            const cadObject = {};

            for (const CAD in cadData) {
                if (cadData.hasOwnProperty(CAD)) {
                    if (Array.isArray(cadData[CAD])) {
                        // Check if field is an array
                        for (let i = 0; i < cadData[CAD].length; i++) {
                            cadObject[`${CAD}[${i}]`] = {
                                DevName: '',
                                DisplayName: '',
                                Value: cadData[CAD][i]
                            };
                        }
                    } else if (typeof cadData[CAD] === 'object') {
                        // Check if CAD is an object
                        for (const field in cadData[CAD]) {
                            if (cadData[CAD].hasOwnProperty(field)) {
                                cadObject[field] = {
                                    DevName: '',
                                    DisplayName: '',
                                    Value: cadData[CAD][field]
                                };
                            }
                        }
                    } else {
                        // Field is string, number of boolean
                        cadObject[CAD] = {
                            DevName: '',
                            DisplayName: '',
                            Value: cadData[CAD]
                        };
                    }
                }
            }

            const record = new RecordItem('', '', '', cadObject);

            const interaction: IInteraction = {
                interactionId: message.interaction.interactionID,
                direction: message.interaction.dType,
                details: record,
                channelType: message.interaction.cType,
                state: message.interaction.iState,
                scenarioId: message.interaction.interactionID
            };

            if (message.interaction.cType === CHANNEL_TYPES.Telephony) {
                let phone = '';
                if (cadData.attributes && cadData.attributes.transferaddress) {
                    phone = cadData.attributes.transferaddress;
                } else if (cadData.callbackNumbers) {
                    phone = cadData.callbackNumbers[0];
                } else if (cadData.displayAddress) {
                    phone = cadData.displayAddress;
                }

                if (phone) {
                    interaction.details.setPhone('', '', phone || '');
                }
            } else if (message.interaction.cType === CHANNEL_TYPES.Email) {
                interaction.details.setEmail('', '', cadData.phone || '');
            } else if (message.interaction.cType === CHANNEL_TYPES.Chat) {
                if (cadData.attributes && cadData.attributes.email) {
                    interaction.details.setEmail('', '', cadData.attributes.email || '');
                }
            }

            if (
                this.newInteraction === false &&
        message.category !== 'disconnect' &&
        message.category !== 'deallocate'
            ) {
                this.newInteraction = true;
            }

            this.addOuteraction(interaction);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : interaction-format : createCRMInteraction : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : interaction-format : createCRMInteraction : ERROR : ${error.name} : ${error.message}`
            );
        }
    }
}
