import { LoggerService } from './../Services/logger.service';
import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { getConfig, IAppConfiguration } from '@amc-technology/davinci-api';

import { PhoneService } from '../Services/phone.service';

import * as PMMessage from './phone.message';

@Component({
    selector: 'app-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.css']
})
export class PhoneComponent implements OnInit {
    iFrameURL: string;
    clientId: string;
    appHeight: string;
    appWidth: string;
    chosenLang: string;
    urlSafety: SafeResourceUrl;
    callDisplay: Array<string>;
    chatDisplay: Array<string>;
    emailDisplay: Array<string>;
    messageDisplay: Array<string>;
    ssoProvider: string;
    ssoOrg: string;

    customInteractionAttributes: Array<string>;
    enableServerSideLogging: boolean;
    embedWebRTCByDefault: boolean;
    hideWebRTCPopUpOption: boolean;
    defaultOutboundSMSCountryCode: string;
    searchTargets: Array<string>;
    callControls: Array<string>;

    constructor(
        private phoneService: PhoneService,
        public sanitizer: DomSanitizer,
        private logger: LoggerService
    ) {}

    addDisplaySetting() {
        try {
            this.logger.logger.logError(
                'Genesys CTI - Component : Phone - addDisplaySetting : START'
            );
            if (this.callDisplay && this.callDisplay.length > 0) {
                this.iFrameURL = this.iFrameURL + '&Call=' + this.callDisplay.join();
            }

            if (this.chatDisplay.length > 0) {
                this.iFrameURL = this.iFrameURL + '&Chat=' + this.chatDisplay.join();
            }

            if (this.emailDisplay.length > 0) {
                this.iFrameURL = this.iFrameURL + '&Email=' + this.emailDisplay.join();
            }

            if (this.messageDisplay.length > 0) {
                this.iFrameURL =
          this.iFrameURL + '&Message=' + this.messageDisplay.join();
            }
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone - addDisplaySetting : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    makeIframURL(result) {
        try {
            this.logger.logger.logError(
                'Genesys CTI - Component : Phone - makeIframURL : START'
            );
            this.iFrameURL = result;
            this.iFrameURL = this.iFrameURL + '&ClientId=' + this.clientId;
            this.addDisplaySetting();

            if (this.embedWebRTCByDefault !== undefined) {
                this.iFrameURL =
          this.iFrameURL + '&embedWebRTCByDefault=' + this.embedWebRTCByDefault;
            }

            if (this.hideWebRTCPopUpOption !== undefined) {
                this.iFrameURL =
          this.iFrameURL +
          '&hideWebRTCPopUpOption=' +
          this.hideWebRTCPopUpOption;
            }
            if (
                this.customInteractionAttributes &&
        this.customInteractionAttributes.length > 0
            ) {
                this.iFrameURL =
          this.iFrameURL +
          '&customInteractionAttributes=' +
          this.customInteractionAttributes.join();
            }

            if (this.enableServerSideLogging !== undefined) {
                this.iFrameURL =
          this.iFrameURL +
          '&enableServerSideLogging=' +
          this.enableServerSideLogging;
            }

            if (this.defaultOutboundSMSCountryCode) {
                this.iFrameURL =
          this.iFrameURL +
          '&defaultOutboundSMSCountryCode=' +
          this.defaultOutboundSMSCountryCode;
            }

            if (this.searchTargets && this.searchTargets.length > 0) {
                this.iFrameURL =
          this.iFrameURL + '&searchTargets=' + this.searchTargets.join();
            }

            if (this.callControls && this.callControls.length > 0) {
                this.iFrameURL =
          this.iFrameURL + '&callControls=' + this.callControls.join();
            }

            if (this.chosenLang) {
                this.iFrameURL = this.iFrameURL + '&Language=' + this.chosenLang;
            }

            if (this.ssoOrg && this.ssoProvider) {
                this.iFrameURL = this.iFrameURL + '&SSOOrg=' + this.ssoOrg;
                this.iFrameURL = this.iFrameURL + '&SSOProvider=' + this.ssoProvider;
            }

            let windowOrigin = '' + window.location;
            if (windowOrigin.endsWith('/')) {
                windowOrigin = windowOrigin.slice(0, -1);
            }
            this.iFrameURL = this.iFrameURL + '&Origin=' + windowOrigin;
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone - makeIframURL : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    ngOnInit() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : ngOnInit : START'
            );

            this.getGenesysURL().then((result) => {
                try {
                    this.logger.logger.logDebug(
                        'Genesys CTI - Component : Phone - getGenesysURL : START'
                    );
                    this.makeIframURL(result);

                    // Checks to see if the current length of the iFrame url is too long for security purposes
                    if (this.iFrameURL.length > 2048) {
                        this.iFrameURL =
              result +
              '&ClientId=' +
              this.clientId +
              '&Language=' +
              this.chosenLang;
                    }

                    this.urlSafety = this.sanitizer.bypassSecurityTrustResourceUrl(
                        this.iFrameURL
                    );
                } catch (error) {
                    this.logger.logger.logError(
                        `Genesys CTI - Component : Phone - getGenesysURL : ERROR : ${error.name} : ${error.message}`
                    );
                }
            });

            this.phoneService.actions.subscribe((data: any) => {
                this.postToIFrame(data);
            });

            this.phoneService.getIFrameURL.subscribe((data: string) => {
                this.urlSafety = this.sanitizer.bypassSecurityTrustResourceUrl(data);
            });
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : ngOnInit : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone : ngOnInit : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Generic method to send any message to the framework
    private postToIFrame(message: PMMessage.IPMMessage): void {
        try {
            this.logger.logger.logInformation(
                `Genesys CTI - Component : Home : grabLogs : POSTING MESSAGE : ${JSON.stringify(
                    message
                )}`
            );

            window.frames['phone'].contentWindow.postMessage(
                JSON.stringify(message),
                '*'
            );

            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : postToIFrame : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone : postToIFrame : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private async getGenesysURL() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : getGenesysURL : START'
            );

            const davinciConfig = this.logger.frameworkConfig;
            const url = davinciConfig.variables.GenesysHost;
            const client = davinciConfig.variables.ClientId;

            this.clientId = client.toString();

            await this.getDavinciValues();

            this.logger.logger.logDebug(
                `Genesys CTI - Component : Phone : getGenesysURL : RETURN : ${url.toString()}`
            );

            return url.toString();
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone : getGenesysURL : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private async getDavinciValues() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : getDavinciValues : START'
            );

            const davinciConfig = await getConfig();

            const call = (davinciConfig.DisplayAttributes as IAppConfiguration)
                .variables.CallDisplay as string[];
            const chat = (davinciConfig.DisplayAttributes as IAppConfiguration)
                .variables.ChatDisplay as string[];
            const email = (davinciConfig.DisplayAttributes as IAppConfiguration)
                .variables.EmailDisplay as string[];
            const message = (davinciConfig.DisplayAttributes as IAppConfiguration)
                .variables.MessageDisplay as string[];

            const height = davinciConfig.variables.Height as string;
            const width = davinciConfig.variables.Width as string;

            const lang = (davinciConfig.ChannelConfig as IAppConfiguration).variables
                .Language as string;

            const org = (davinciConfig.Sso as IAppConfiguration).variables
                .SsoOrg as string;
            const provider = (davinciConfig.Sso as IAppConfiguration).variables
                .SsoProvider as string;

            this.chosenLang = lang;
            this.callDisplay = call;
            this.customInteractionAttributes = (
                davinciConfig.CustomInteractionAttributes as IAppConfiguration
            ).variables.CustomAttributes as string[];
            this.enableServerSideLogging = (
                davinciConfig.ChannelConfig as IAppConfiguration
            ).variables.EnableServerSideLogging as boolean;
            this.embedWebRTCByDefault = (
                davinciConfig.ChannelConfig as IAppConfiguration
            ).variables.EmbedWebRTCByDefault as boolean;
            this.hideWebRTCPopUpOption = (
                davinciConfig.ChannelConfig as IAppConfiguration
            ).variables.HideWebRTCPopUpOption as boolean;
            this.defaultOutboundSMSCountryCode = (
                davinciConfig.ChannelConfig as IAppConfiguration
            ).variables.DefaultOutboundSMSCountryCode as string;

            this.searchTargets = (davinciConfig.ChannelConfig as IAppConfiguration)
                .variables.SearchTargets as string[];
            this.callControls = (davinciConfig.CallControls as IAppConfiguration)
                .variables.EnabledControls as string[];

            this.chatDisplay = chat;
            this.emailDisplay = email;
            this.messageDisplay = message;

            this.validateSSO(org, provider);
            this.appHeight = this.configureDimensions(height);
            this.appWidth = this.configureDimensions(width);

            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : getDavinciValues : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone : getDavinciValues : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private configureDimensions(param: string) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : configureDimensions : START'
            );

            if (param.includes('%')) {
                this.logger.logger.logDebug(
                    `Genesys CTI - Component : Phone : configureDimensions : RETURN : ${param}`
                );
                return param;
            } else if (
                param.includes('px') &&
        !Number.isNaN(parseInt(param.replace('px', ''), 10))
            ) {
                this.logger.logger.logDebug(
                    `Genesys CTI - Component : Phone : configureDimensions : RETURN : ${param}`
                );
                return param;
            } else if (!Number.isNaN(parseInt(param, 10))) {
                this.logger.logger.logDebug(
                    `Genesys CTI - Component : Phone : configureDimensions : RETURN : ${param}`
                );
                return param + 'px';
            } else {
                this.logger.logger.logDebug(
                    `Genesys CTI - Component : Phone : configureDimensions : RETURN : ${param}`
                );
                return '100%';
            }
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone : configureDimensions : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private validateSSO(passedOrg: string, passedProvider: string) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : validateSSO : START'
            );

            if (
                passedOrg === '' ||
        passedOrg === undefined ||
        passedOrg === null ||
        passedProvider === ''
            ) {
                this.ssoProvider = '';
                this.ssoOrg = '';
            } else {
                this.ssoProvider = passedProvider;
                this.ssoOrg = passedOrg;
            }

            this.logger.logger.logDebug(
                'Genesys CTI - Component : Phone : validateSSO : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Phone : validateSSO : ERROR : ${error.name} : ${error.message}`
            );
        }
    }
}
