import { IClientConfiguration } from './../models/IClientConfiguration';
import { IPMAddCustomAttribute } from './../phone/phone.message';
import { Component, OnInit } from '@angular/core';
import {
    setAppHeight,
    initializeComplete,
    registerOnPresenceChanged,
    enableClickToDial,
    registerClickToDial,
    SearchRecords,
    IInteraction,
    registerContextualControls,
    IContextualContact,
    setSupportedChannels,
    CHANNEL_TYPES,
    setPresence,
    getConfig,
    IAppConfiguration,
    logout,
    registerOnLogout
} from '@amc-technology/davinci-api';

import { PhoneService } from '../Services/phone.service';
import { PresenceSyncService } from '../Services/presence-sync.service';
import { InteractionFormatService } from '../Services/interaction-format.service';

import * as PMMessage from '../phone/phone.message';
import { ActivityFocusService } from '../Services/activity-focus.service';
import { LoggerService } from '../Services/logger.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    appName = 'NewGenesys';
    userId: string;
    clientId: string;
    logoutURLprefix: string;
    address: string;
    frameworkConfig: IAppConfiguration;

    constructor(
        private phoneService: PhoneService,
        private presenceSync: PresenceSyncService,
        private iFormat: InteractionFormatService,
        private aFocus: ActivityFocusService,
        private logger: LoggerService
    ) {
        this.address = '';
    }

    async ngOnInit() {
        try {
            this.logger.logger.logInformation(
                'Genesys CTI - Component : Home : ngOnInit : STARTING APP EXECUTION'
            );

            // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
            // Add 'implements OnInit' to the class.
            setAppHeight(480);
            enableClickToDial(true);
            this.frameworkConfig = this.logger.frameworkConfig;

            this.appName = <string>(<unknown>this.frameworkConfig.name);
            this.clientId = this.frameworkConfig.variables.ClientId.toString();
            this.logoutURLprefix =
        this.frameworkConfig.variables.GenesysHost.toString();

            if (!this.appName || !this.clientId || !this.logoutURLprefix) {
                this.logger.logger.logDebug(
                    'Genesys CTI - Component : Home : onInit : Config read error'
                );
            } else {
                this.logoutURLprefix = this.extractDomain(this.logoutURLprefix);
            }

            registerOnPresenceChanged(this.presenceChanged.bind(this));
            registerClickToDial(
                (phoneNumber: string, records: SearchRecords) =>
                    new Promise((resolve, reject) => {
                        this.logger.logger.logInformation(
                            `Genesys CTI - Component : Home : registerClickToDial : CALLING : ${phoneNumber}`
                        );

                        this.phoneService.dial(phoneNumber);
                    })
            );
            registerContextualControls(
                (contact: IContextualContact) =>
                    new Promise((resolve, reject) => {
                        this.phoneService.dial(contact.uniqueId);
                    })
            );
            await this.presenceSync.createMaps();
            this.grabLogs();

            registerOnLogout(async () => {
                try {
                    this.logger.logger.logDebug(
                        'Genesys CTI - Component : Home - registerOnLogout START '
                    );

                    await this.logger.logger.pushLogsAsync();

                    this.phoneService.changeIFrameURL(
                        'https://login.' +
              this.logoutURLprefix +
              // '/logout?response_type=token&client_id=' +
              '/logout?client_id=' +
              this.clientId +
              '&redirect_uri=https://apps.' +
              this.logoutURLprefix +
              '/crm/index.html'
                    );
                    this.logger.logger.logDebug(
                        'Genesys CTI - Component : Home - registerOnLogout END'
                    );
                    return Promise.resolve();
                } catch (err) {
                    this.logger.logger.logDebug(
                        `Genesys CTI - Component : Home : registerOnLogout : Error :  ${err.message}`
                    );
                    return Promise.reject();
                }
            });

            initializeComplete(this.logger.logger);

            this.logger.logger.logDebug(
                'Genesys CTI - Component : Home : ngOnInit : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Home : ngOnInit : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    extractDomain(url: string) {
        this.logger.logger.logDebug(
            'Genesys CTI - Component : Home : extractDomain - START'
        );
        try {
            let result: string;
            let match: string[];
            if (
                (match = url.match(
                    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
                ))
            ) {
                result = match[1];
                if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
                    result = match[1];
                }
            }
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Home : extractDomain - END'
            );
            return result;
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Home : extractDomain - Error : ${error.message} `
            );
        }
    }

    private async presenceChanged(
        presence: string,
        reason: string,
        initiatingApp: string
    ) {
        this.logger.logger.logInformation(
            `Genesys CTI - Component : Home : registerOnPresenceChanged : PRESENCE CHANGED TO : ${presence} : ${reason} : ${initiatingApp}`
        );

        if (this.appName !== initiatingApp) {
            this.presenceSync.onPresenceChangedVerification(presence, reason);
        }
    }

    private handleInteraction(data) {
        if (
            data.data.category === 'disconnect' ||
      data.data.category === 'deallocate'
        ) {
            this.address = '';
            this.presenceSync.interactionChanged(data);
            this.iFormat.addInteraction(data);
        } else if (data.data.category === 'change') {
            if (data.data.interaction.new.displayAddress !== '') {
                this.address = data.data.interaction.new.displayAddress;
                this.presenceSync.interactionChanged(data);
                this.iFormat.addInteraction(data);
            } else {
                if (data.data.interaction.isDialerPreview) {
                    this.address = data.data.interaction.callbackNumbers[0];
                }
                this.presenceSync.interactionChanged(data);
                this.iFormat.addInteraction(data);
            }
        } else if (data.data.category === 'consultTransfer') {
            const customAttributeMsg: PMMessage.IPMAddCustomAttribute = {
                type: PMMessage.PM_ADD_CUSTOM_ATTRIBUTE,
                interactionId: data.data.interaction,
                attributes: { DaVinciTransferAddress: this.address }
            };
            this.phoneService.addActions(customAttributeMsg);
            this.presenceSync.interactionChanged(data);
            this.iFormat.addInteraction(data);
        } else {
            if (data.data.interaction.displayAddress !== '') {
                if (data.data.interaction.displayAddress !== 'Internal') {
                    this.address = data.data.interaction.displayAddress;
                }
                this.presenceSync.interactionChanged(data);
                this.iFormat.addInteraction(data);
            } else {
                if (data.data.interaction.isDialerPreview) {
                    this.address = data.data.interaction.callbackNumbers[0];
                }
                this.presenceSync.interactionChanged(data);
                this.iFormat.addInteraction(data);
            }
        }
    }

    // This will be called in ngOnInit(), and will setup the listener for data passed from phoneService
    private grabLogs() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Home : grabLogs : START'
            );

            this.phoneService.notifications.subscribe(async (data: any) => {
                this.logger.logger.logInformation(
                    `Genesys CTI - Component : Home : grabLogs : RECEIVED EVENT : ${JSON.stringify(
                        data
                    )}`
                );

                switch (data.type) {
                    case PMMessage.PM_INITIAL_SETUP:
                        break;
                    case PMMessage.PM_INTERACTION:
                        this.handleInteraction(data);
                        break;
                    case PMMessage.PM_INTERACTION_STATE_CHANGE:
                        this.presenceSync.interactionChanged(data);
                        this.iFormat.addInteraction(data);
                        break;
                    case PMMessage.PM_STATUS_UPDATE:
                        if (data.category === 'status') {
                            this.presenceSync.userStatusChanged(
                                data.category,
                                data.data.status
                            );
                        } else if (data.category === 'station') {
                            this.userId = data.data.primaryEdge.name;
                            this.checkUserId();
                        } else if (data.category === 'routingStatus') {
                            // Placeholder for future if we need to handle change of routing status
                            this.presenceSync.routingStatusChanged(data);
                        } else if (data.category === 'modal') {
                            // Placeholder for future if we need to handle change of modal
                        } else if (data.category === 'logout') {
                            await this.logger.logger.pushLogsAsync();
                            logout();
                        }
                        break;
                    case PMMessage.PM_NOTIFICATION:
                        if (data.data.category === 'interactionSelection') {
                            data = {
                                selectedId: data.data.interactionId,
                                participantId: data.data.participantId
                            };
                            this.aFocus.updateFocus(data);
                        }
                        break;
                    default:
                        throw new Error(`Invalid data from PhoneService: ${data}`);
                }
            });
            this.iFormat.outeractions.subscribe((data: IInteraction) => {
                this.aFocus.addInteraction(data);
            });

            this.logger.logger.logDebug(
                'Genesys CTI - Component : Home : grabLogs : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Home : grabLogs : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // checks if this.userId is undefined
    // if not, calls setSupportedChannels and initializeComplete
    // if yes, sets a timer to check again
    private checkUserId() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Component : Home : checkUserId : START'
            );

            setSupportedChannels([
                {
                    channelType: CHANNEL_TYPES.Telephony,
                    idName: 'Phone',
                    id: this.userId
                }
            ]);

            this.logger.logger.logDebug(
                'Genesys CTI - Component : Home : checkUserId : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Home : checkUserId : ERROR : ${error.name} : ${error.message}`
            );
        }
    }
}
