import { Injectable } from '@angular/core';
import {
    getConfig,
    IAppConfiguration,
    setPresence
} from '@amc-technology/davinci-api';

import * as PMMessage from '../phone/phone.message';
import { PhoneService } from './phone.service';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class PresenceSyncService {
    d2cMap: Map<string, string>; // DaVinci to Genesys Status Map
    c2dMap: Map<string, string>; // Genesys to DaVinci Status Map
    genesysMap: Map<string, string>; // Genesys Presence String to Their Predefined Code
    private GenesysStatus: string; // saved Genesys status
    private LocalGenesysStatus: string; // saved Genesys status
    private DaVinciPresence: string; // saved Global Presence status
    private interActionList: Set<string>;
    private isOnInteraction: boolean;
    private onInteractionPresnece: string;

    constructor(
        private phoneService: PhoneService,
        private logger: LoggerService
    ) {
        this.GenesysStatus = '';
        this.LocalGenesysStatus = '';
        this.DaVinciPresence = '';
        this.interActionList = new Set();
        this.isOnInteraction = false;
    }

    // pulled the functionality of the previous two methods into this singular method, now we only make a single getConfig call
    public async createMaps() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : presence-sync : createMaps : START'
            );

            const davinciConfig = this.logger.frameworkConfig;
            const d2c = (davinciConfig.PresenceConfig as IAppConfiguration).variables
                .DaVinciToChannel;
            const c2d = (davinciConfig.PresenceConfig as IAppConfiguration).variables
                .ChannelToDaVinci;

            const gMap = (davinciConfig.PresenceConfig as IAppConfiguration).variables
                .GenesysStatusID;

            this.d2cMap = new Map(Object.entries(d2c));
            this.c2dMap = new Map(Object.entries(c2d));

            this.genesysMap = new Map(Object.entries(gMap));

            this.onInteractionPresnece = (
                davinciConfig.PresenceConfig as IAppConfiguration
            ).variables.OnInteractionPresence as string;

            this.logger.logger.logDebug(
                'Genesys CTI - Service : presence-sync : createMaps : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : presence-sync : createMaps : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    public onPresenceChangedVerification(presence: string, reason: string) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : presence-sync : onPresenceChangedVerification : START'
            );

            let newDaVinciPresence = presence;
            if (!newDaVinciPresence) {
                this.logger.logger.logError(
                    'Genesys CTI - Service : presence-sync : onPresenceChangedVerification : presence is null or empty'
                );
                return;
            }
            if (reason) {
                newDaVinciPresence += '|' + reason;
            }
            const newLocalChannelStatus = this.d2cMap.get(newDaVinciPresence);

            if (newLocalChannelStatus) {
                if (
                    !this.doesLocalGenesysStatusMatch(newLocalChannelStatus) ||
          !this.doesDaVinciPresenceMatch(newDaVinciPresence)
                ) {
                    let newChannelStatus = newLocalChannelStatus;
                    if (newLocalChannelStatus.startsWith('Pending ')) {
                        if (!this.isOnInteraction) {
                            return;
                        }
                        newChannelStatus = newChannelStatus.replace('Pending ', '');
                    }
                    const statusMessage: PMMessage.IPMStatusUpdate = {
                        type: PMMessage.PM_STATUS_UPDATE,
                        category: 'Status',
                        status: newChannelStatus,
                        genesysID: this.genesysMap.get(newChannelStatus)
                    };
                    // Sends to PEF
                    this.phoneService.addActions(statusMessage);
                }

                setPresence(presence, reason);
                this.setLocalGenesysStatus(newLocalChannelStatus);
                this.setGenesysStatus(newLocalChannelStatus);
                this.setDavinciPresence(newDaVinciPresence);

                this.logger.logger.logDebug(
                    'Genesys CTI - Service : presence-sync : onPresenceChangedVerification : END'
                );
            } else {
                this.logger.logger.logDebug(
                    'Genesys CTI - Service : presence-sync : onPresenceChangedVerification : ERROR : requested status does not exist'
                );
            }
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : presence-sync : onPresenceChangedVerification : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // When a User Action is passed from the framework it reaches this method
    // At this point all the method does is alter the presence, might add more functionality for additional User Actions later
    public userStatusChanged(category: string, status: string): void {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : presence-sync : userStatusChanged : START'
            );

            let newGenesysStatus =
        status.substring(0, 1) + status.substring(1).toLowerCase();
            newGenesysStatus = newGenesysStatus.replace(/_/g, ' ');

            let newLocalGenesysStatus = newGenesysStatus;

            if (this.isOnInteraction) {
                newLocalGenesysStatus = 'Pending ' + newLocalGenesysStatus;
            }
            const newDaVinciStatus: string = this.c2dMap.get(newLocalGenesysStatus);

            if (newDaVinciStatus) {
                this.setGenesysStatus(newGenesysStatus);
                if (
                    !this.doesLocalGenesysStatusMatch(newGenesysStatus) ||
          !this.doesDaVinciPresenceMatch(newDaVinciStatus)
                ) {
                    const presenceArray: string[] = newDaVinciStatus.split('|');
                    const newPresence: string = presenceArray[0];

                    if (presenceArray.length > 1) {
                        const newReason = presenceArray[1];
                        setPresence(newPresence, newReason);
                    } else {
                        setPresence(newPresence);
                    }

                    this.setDavinciPresence(newDaVinciStatus);
                    this.setLocalGenesysStatus(newGenesysStatus);
                }
            }

            this.logger.logger.logDebug(
                'Genesys CTI - Service : presence-sync : userStatusChanged : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : presence-sync : userStatusChanged : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    routingStatusChanged(data) {}

    interactionChanged(data) {
        switch (data.data.category) {
            case 'connect':
                this.interActionList.add(data.data.interaction.id);
                if (!this.isOnInteraction) {
                    this.isOnInteraction = true;
                    setTimeout(() => {
                        setPresence(this.onInteractionPresnece);
                        this.setDavinciPresence(this.onInteractionPresnece);
                    }, 1000);
                }
                break;
            case 'disconnect':
                this.interActionList.delete(data.data.interaction.id);
                if (this.interActionList.size === 0) {
                    this.isOnInteraction = false;
                    this.userStatusChanged('', this.GenesysStatus);
                }
                break;
            default:
        }
    }

    // Updates the status variable in presence-sync to the passed value
    private setLocalGenesysStatus(value: string) {
        this.LocalGenesysStatus = value;
    }

    // Updates the status variable in presence-sync to the passed value
    private setGenesysStatus(value: string) {
        this.GenesysStatus = value;
    }

    // Verifies whether or not the passed value matches the current value
    private doesLocalGenesysStatusMatch(value: string) {
        return this.LocalGenesysStatus === value;
    }

    // Updates the presence variable in presence-sync to the passed value
    private setDavinciPresence(value: string) {
        this.DaVinciPresence = value;
    }

    // Verifies whether or not the passed value matches the current value
    private doesDaVinciPresenceMatch(value: string) {
        return this.DaVinciPresence === value;
    }

}
