import {
    INTERACTION_DIRECTION_TYPES,
    CHANNEL_TYPES,
    INTERACTION_STATES
} from '@amc-technology/davinci-api';

// Message format to and from PureCloud Embeddable Framework

export const PM_DIAL = '[PM] Dial';
export const PM_INTERACTION_STATE_CHANGE = '[PM] Interaction State Change';
export const PM_INITIAL_SETUP = '[PM] Initial Setup';
export const PM_NOTIFICATION = '[PM] Notification';
export const PM_STATUS_UPDATE = '[PM] User Status Update';
export const PM_FOCUS_UPDATE = '[PM] Focus Update';
export const PM_INTERACTION = '[PM] Interaction';
export const PM_ADD_CUSTOM_ATTRIBUTE = '[PM] Add Custom Attribute';
export const PM_ADD_CUSTOM_TRANSFER_CONTEXT =
  '[PM] Add Custom Transfer Context';

export interface IPMMessage {
    type: string;
}

export interface IPMCategory extends IPMMessage {
    category: string;
}

export interface IPMFocusUpdate extends IPMMessage {
    data: {
        selectedId: string;
        participantId: string;
    };
}

export interface IPMDialMessage extends IPMMessage {
    number: string;
    autoPlace: boolean;
}

export interface IPMInteractionStateChangeMessage extends IPMCategory {
    interaction: {
        cType: CHANNEL_TYPES;
        dType: INTERACTION_DIRECTION_TYPES;
        iState: INTERACTION_STATES;
        devName: string;
        interactionID: string;
    };
}

export interface IPMStatusUpdate extends IPMCategory {
    status: string;
    genesysID: string;
}

export interface IPMAddCustomAttribute {
    type: string;
    interactionId: string;
    attributes: Object;
}

export interface IPMAddTransferContext {
    type: string;
    name: string;
    attibutes: Object;
}

export interface IPMNotificationMessage extends IPMMessage {
    message: string;
    messageType: string;
}
