import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { getConfig, IAppConfiguration } from '@amc-technology/davinci-api';

import * as PMMessage from '../phone/phone.message';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class PhoneService {
    private PFE_ORIGIN: string;
    private actionSource = new Subject();
    private actions$ = this.actionSource.asObservable();
    private notificationSource = new Subject();
    private notifications$ = this.notificationSource.asObservable();
    private changeIFrameURLSource = new Subject();
    private changeIFrameURL$ = this.changeIFrameURLSource.asObservable();
    private frameworkConfig: IAppConfiguration;
    constructor(private logger: LoggerService) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : phone : constructor : START'
            );
            this.frameworkConfig = this.logger.frameworkConfig;

            const host = (
                this.frameworkConfig.variables['GenesysHost'] as string
            ).split('/');
            this.PFE_ORIGIN = `${host[0]}//${host[2]}`;

            window.addEventListener('message', (ev: MessageEvent) => {
                this.logger.logger.logInformation(
                    `Genesys CTI - Service : phone : constructor : RECEIVED MESSAGE : ${JSON.stringify(
                        ev
                    )}`
                );

                if (ev.origin !== this.PFE_ORIGIN) {
                    this.logger.logger.logDebug(
                        'Genesys CTI - Service : phone : constructor : NOTIFICATION NOT SENT - ORIGIN DIFFERENT : END'
                    );
                    return;
                }
                this.notificationSource.next(JSON.parse(ev.data));
            });

            this.logger.logger.logDebug(
                'Genesys CTI - Service : phone : constructor : NOTIFICATION SENT : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : phone : constructor : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    public get actions() {
        return this.actions$;
    }

    public get notifications() {
        return this.notifications$;
    }

    public get getIFrameURL() {
        return this.changeIFrameURL$;
    }

    public addActions(data: any) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : phone : addActions : START'
            );

            this.actionSource.next(data);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : phone : addActions : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : phone : addActions : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    /** For CRM to invoke a click-to-dial action */
    public dial(phoneNumber: string): void {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : phone : dial : START'
            );

            const dialMessage: PMMessage.IPMDialMessage = {
                type: PMMessage.PM_DIAL,
                number: phoneNumber,
                autoPlace: true
            };
            this.actionSource.next(dialMessage);

            this.logger.logger.logDebug('Genesys CTI - Service : phone : dial : END');
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : phone : dial : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    public changeIFrameURL(newURL: string) {
        this.changeIFrameURLSource.next(newURL);
    }
}
