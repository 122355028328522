import { IClientConfiguration } from './../models/IClientConfiguration';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Logger,
    LOG_SOURCE,
    AbstractLogger,
    getDaVinciAgentConfig,
    LOGGER_TYPE,
    ConsoleLogger,
    FileLogger,
    PremiseLogger,
    getConfig
} from '@amc-technology/davinci-api';
import {
    ILoggerConfiguration,
    defaultLoggerConfiguration
} from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    public frameworkConfig: any;

    private _logger?: Logger;
    private _configUrlPath = 'ClientConfiguration';

    constructor(private _http: HttpClient) {
    }

    get logger() {
        if (!this._logger) {
            throw new Error('Logger not initialized.');
        }

        return this._logger;
    }

    public initializeLogger() {
        return this._http
            .get<IClientConfiguration>(this._configUrlPath)
            .toPromise()
            .then(async (serverConfig: any) => {

                try {
                // Get raw config. Framework does not perform data validation intentionally
                    const rawConfig = await getDaVinciAgentConfig();
                    // Perform data validation, revert to defaults if configs are invalid
                    // or undefined
                    const loggerConfig: ILoggerConfiguration = {
                        'Log Level': parseInt(
                            rawConfig?.variables?.['Log Level']?.toString() ||
                      defaultLoggerConfiguration['Log Level'].toString(),
                            10
                        ),
                        'Logger Type':
                    rawConfig?.variables?.['Logger Type']?.toString() ||
                    defaultLoggerConfiguration['Logger Type'],
                        'Premise Logger URL':
                    rawConfig?.variables?.['Premise Logger URL']?.toString() ||
                    defaultLoggerConfiguration['Premise Logger URL'],
                        'Console Logger': {
                            'Max Length': parseInt(
                                rawConfig?.['Console Logger']?.variables?.[
                                    'Max Length'
                                ]?.toString() ||
                          defaultLoggerConfiguration['Console Logger']['Max Length'],
                                10
                            )
                        }
                    };
                    this._logger = new Logger(
                        'PureCloud',
                        false,
                        serverConfig.loggerApiUrl
                    );
                    this._logger.setConfiguration(loggerConfig);
                    this._logger.logInformation('PureCloud logger is initiated');
                } catch (err) {
                    (this._logger = new Logger(
                        'PureCloud',
                        false,
                        serverConfig.loggerApiUrl
                    )).logCritical('loggerService.initiialize(): Error creating logger!');
                }

            })
            .catch((err: any) => console.error(err));
    }

    public initializeFrameworkConfig() {
        return getConfig()
            .then((framworkConfig: any) => (this.frameworkConfig = framworkConfig))
            .catch((err: any) => console.error(err));
    }
}
