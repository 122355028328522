import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, APP_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PhoneComponent } from './phone/phone.component';

import { PhoneService } from './Services/phone.service';
import { PresenceSyncService } from './Services/presence-sync.service';
import { InteractionFormatService } from './Services/interaction-format.service';
import { LoggerService } from './Services/logger.service';

export function initLogger(loggerService: LoggerService) {
    return async () => await loggerService.initializeLogger();
}

export function initFrameworkConfig(loggerService: LoggerService) {
    return () => loggerService.initializeFrameworkConfig();
}

@NgModule({
    declarations: [AppComponent, HomeComponent, PhoneComponent],
    imports: [BrowserModule, HttpClientModule, FormsModule],
    providers: [
        PhoneService,
        PresenceSyncService,
        InteractionFormatService,
        LoggerService,
        { provide: APP_ID, useValue: 'ng-cli-universal' },
        {
            provide: APP_INITIALIZER,
            useFactory: initLogger,
            deps: [LoggerService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initFrameworkConfig,
            deps: [LoggerService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
