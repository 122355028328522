import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
    CHANNEL_TYPES,
    IInteraction,
    INTERACTION_STATES,
    setInteraction,
    IAppConfiguration
} from '@amc-technology/davinci-api';
import { IPMFocusUpdate } from '../phone/phone.message';

@Injectable({
    providedIn: 'root'
})
export class ActivityFocusService {
    frameworkConfig: IAppConfiguration;
    genesysHost: string;
    // Create an observable to get interactions from the Interaction-Format service
    private incomingInteraction = new Subject();

    // Create an observable that gets focus updates from user inputs
    private userFocusUpdate = new Subject();

    // the activity that is currently focused by the app
    private currentActivity: IActivityItem;

    // collection of all activities that are active in the app
    private currentActivities: Array<IActivityItem>;

    // The most recent activity to arrive to the service
    private newActivity: IActivityItem;

    private updateID: string;
    private updateActivity: IActivityItem;

    constructor(private logger: LoggerService) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : constructor : START'
            );

            this.currentActivity = null;
            this.newActivity = null;
            this.currentActivities = [];
            this.updateID = null;
            this.updateActivity = null;
            this.frameworkConfig = this.logger.frameworkConfig;
            this.genesysHost = this.frameworkConfig.variables.GenesysHost.toString();
            if (!this.genesysHost || this.genesysHost === '') {
                this.logger.logger.logDebug(
                    'Genesys CTI - Service : activity-focus : onInit : Genesys Host Config read error'
                );
            } else {
                const extractDomain = this.extractDomain(this.genesysHost);
                this.genesysHost = extractDomain[0];
            }

            this.incomingInteraction.subscribe((interaction: IInteraction) => {
                // Convert the incoming interaction into the ActivityItem object
                this.convertInteractionToActivity(interaction);

                // The activity is not in the currentActivities array
                if (!this.checkIfActivityExists(this.newActivity)) {
                    this.addToCurrentActivities(this.newActivity);

                    // Check if there is a currentActivity, and set the new activity to currentActivity if not
                    // EDIT: Potentially changing the functionality of the events given my talk with Pavan
                    if (
                        this.currentActivity === null ||
            this.newActivity.state === INTERACTION_STATES.Alerting ||
            this.newActivity.state === INTERACTION_STATES.Initiated
                    ) {
                        this.setCurrentActivity(this.newActivity);
                    }
                } else {
                    // The activity is in the currentActivities array

                    // The currentActivity is ending or has a state of disconnect
                    if (this.newActivity.state === INTERACTION_STATES.Disconnected) {
                        // Remove it from the currentActivities array, and set a new currentActivity if needed
                        this.removeFromCurrentActivities(this.newActivity);
                    }

                    // There is a currentActivity and the newActivity matches
                    if (this.isCurrentActivity(this.newActivity)) {
                        // All interactions that come in will have userFocus sent to false
                        // So if it matches the currentActivity it must be set to true
                        this.setCurrentActivity(this.newActivity);
                    }
                }

                // Adding the scriptURL for CRM Panel app to pop out if configured
                // When a script is attached to an inbound call, pop up the script in the CRM Panel
                if (
                    this.newActivity.channelType === CHANNEL_TYPES.Telephony &&
          this.newActivity.direction === 0
                ) {
                    this.newActivity.details.fields.scriptURL = {
                        DevName: '',
                        DisplayName: '',
                        // eslint-disable-next-line max-len
                        Value: `${this.genesysHost}interaction.html#url=https://apps.mypurecloud.com/directory/?disableSoftphone=true&disableProactiveDevicePermissions=true#/interaction/${this.newActivity.interactionId}?panels=scripts,responses,agent-assist%26builtInAgentAssistInterapptionsProvider,details,wrapup&initialPanel=none&disableProactiveDevicePermissions=true#/interaction/${this.newActivity.interactionId}?panels=scripts,responses,agent-assist&initialPanel=none&builtInAgentAssistInterapptionsProvider,details,wrapup=undefined&identifier=interaction${this.newActivity.interactionId}`
                    };
                }

                // Adding the editURL for CRM Panel app to pop out if configured
                if (this.newActivity.channelType !== CHANNEL_TYPES.Telephony) {
                    this.newActivity.details.fields.editURL = {
                        DevName: '',
                        DisplayName: '',
                        // eslint-disable-next-line max-len
                        Value: `https://apps.mypurecloud.com/crm/interaction.html#url=https:%2F%2Fapps.mypurecloud.com%2Fdirectory%2F%3FdisableSoftphone=true&disableProactiveDevicePermissions=true%23%2Finteraction%2F${this.newActivity.interactionId}%3Fpanels=scripts,responses,details,wrapup&initialPanel=none&disableProactiveDevicePermissions=true%23%2Finteraction%2F${this.newActivity.interactionId}%3Fpanels=scripts,responses,details,wrapup&initialPanel=none&identifier=interaction${this.newActivity.interactionId}`
                    };
                }

                setInteraction(this.newActivity);
            });

            this.userFocusUpdate.subscribe((update: IPMFocusUpdate) => {
                this.logger.logger.logInformation(
                    `Genesys CTI - Service : activity-focus : userFocusUpdate : RECEIVED USER FOCUS UPDATE : ${JSON.stringify(
                        update
                    )}`
                );

                // Get the interactionId and pass it to the updateID variable
                this.updateID = update.data.selectedId;

                // Check whether the updateID matches something in the currentActivities array
                this.updateActivity = this.findUpdateActivity(this.updateID);

                // the updateID doesn't match something in the currentActivities array
                if (this.updateActivity === null) {
                } else {
                    // the updateID does match something in the currentActivities array

                    // set the updateActivity to the currentActivity
                    this.setCurrentActivity(this.updateActivity);

                    // Adding the scriptURL for CRM Panel app to pop out if configured
                    // When a script is attached to an inbound call, pop up the script in the CRM Panel
                    if (
                        this.newActivity.channelType === CHANNEL_TYPES.Telephony &&
            this.newActivity.direction === 0
                    ) {
                        this.newActivity.details.fields.scriptURL = {
                            DevName: '',
                            DisplayName: '',
                            // eslint-disable-next-line max-len
                            Value: `${this.genesysHost}interaction.html#url=https://apps.mypurecloud.com/directory/?disableSoftphone=true&disableProactiveDevicePermissions=true#/interaction/${this.newActivity.interactionId}?panels=scripts,responses,agent-assist%26builtInAgentAssistInterapptionsProvider,details,wrapup&initialPanel=none&disableProactiveDevicePermissions=true#/interaction/${this.newActivity.interactionId}?panels=scripts,responses,agent-assist&initialPanel=none&builtInAgentAssistInterapptionsProvider,details,wrapup=undefined&identifier=interaction${this.newActivity.interactionId}`
                        };
                    }

                    // Adding the editURL for CRM Panel app to pop out if configured
                    if (this.newActivity.channelType !== CHANNEL_TYPES.Telephony) {
                        this.newActivity.details.fields.editURL = {
                            DevName: '',
                            DisplayName: '',
                            // eslint-disable-next-line max-len
                            Value: `https://apps.mypurecloud.com/crm/interaction.html#url=https:%2F%2Fapps.mypurecloud.com%2Fdirectory%2F%3FdisableSoftphone=true&disableProactiveDevicePermissions=true%23%2Finteraction%2F${this.newActivity.interactionId}%3Fpanels=scripts,responses,details,wrapup&initialPanel=none&disableProactiveDevicePermissions=true%23%2Finteraction%2F${this.newActivity.interactionId}%3Fpanels=scripts,responses,details,wrapup&initialPanel=none&identifier=interaction${this.newActivity.interactionId}`
                        };
                    }

                    setInteraction(this.updateActivity);
                }
            });

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : constructor : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : constructor : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // public method to send an interaction to this service
    public addInteraction(activity: IInteraction) {
        try {
            this.logger.logger.logInformation(
                `Genesys CTI - Service : activity-focus : incomingInteraction : RECEIVED INTERACTION : ${JSON.stringify(
                    activity
                )}`
            );

            this.incomingInteraction.next(activity);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : addInteraction : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : addInteraction : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // public method to update which interaction should be the currentActivity
    public updateFocus(update: IPMFocusUpdate) {
        try {
            this.logger.logger.logInformation(
                `Genesys CTI - Service : activity-focus : incomingInteraction : RECEIVED INTERACTION : ${JSON.stringify(
                    update
                )}`
            );

            this.userFocusUpdate.next(update);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : updateFocus : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : updateFocus : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private checkIfActivityExists(activity: IActivityItem) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : checkIfActivityExists : START'
            );

            for (let i = 0; i < this.currentActivities.length; i++) {
                if (
                    activity.interactionId === this.currentActivities[i].interactionId
                ) {
                    this.logger.logger.logDebug(
                        'Genesys CTI - Service : activity-focus : checkIfActivityExists : RETURN : true'
                    );

                    return true;
                }
            }

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : checkIfActivityExists : RETURN : false'
            );

            return false;
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : checkIfActivityExists : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Adds an interaction to the currentActivities array
    private addToCurrentActivities(activity: IActivityItem) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : addToCurrentActivities : START'
            );

            this.currentActivities.push(activity);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : addToCurrentActivities : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : addToCurrentActivities : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Removes an interaction from the currentActivities array and splices it
    private removeFromCurrentActivities(activity: IActivityItem) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : removeFromCurrentActivities : START'
            );

            if (this.isCurrentActivity(activity)) {
                if (!this.areThereOtherActivities()) {
                    this.removeCurrentFocus();
                } else {
                    const nextActivity = this.findOtherActivity();
                    if (nextActivity !== null) {
                    }
                    this.setCurrentActivity(nextActivity);
                }
            }

            const index = this.currentActivities.indexOf(activity);
            this.currentActivities.splice(index, 1);

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : removeFromCurrentActivities : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : removeFromCurrentActivities : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Checks whether the passed activity is the same as the currentFocus
    // Like checkIfActivityExists, this will likely not work correctly as is
    // UPDATE: I changed this over to the interface, hopefully it will work as intended
    private isCurrentActivity(activity: IActivityItem) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : isCurrentActivity : START'
            );

            if (activity === null || this.currentActivity === null) {
                this.logger.logger.logDebug(
                    'Genesys CTI - Service : activity-focus : isCurrentActivity : RETURN : null'
                );
                return null;
            } else if (
                activity.interactionId === null ||
        this.currentActivity.interactionId === null
            ) {
                this.logger.logger.logDebug(
                    'Genesys CTI - Service : activity-focus : isCurrentActivity : RETURN : null'
                );
                return null;
            } else {
                this.logger.logger.logDebug(
                    `Genesys CTI - Service : activity-focus : isCurrentActivity : RETURN : ${
                        activity.interactionId === this.currentActivity.interactionId
                    }`
                );
                return activity.interactionId === this.currentActivity.interactionId;
            }
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : isCurrentActivity : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Sets the passed interaction as the currentFocus of the PureCloud app
    private setCurrentActivity(activity: IActivityItem) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : setCurrentActivity : START'
            );

            if (this.currentActivity !== null) {
                this.currentActivity.userFocus = false;
            }

            this.currentActivity = activity;
            this.currentActivity.userFocus = true;

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : setCurrentActivity : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : setCurrentActivity : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Sets the currentFocus to null
    // Only should be used when the currentFocus has a status of disconnect or deallocate
    // And there are no other interactions in currentActivities
    private removeCurrentFocus() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : removeCurrentFocus : START'
            );

            if (this.currentActivity !== null) {
                this.currentActivity.userFocus = false;
            }
            this.currentActivity = null;

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : removeCurrentFocus : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : removeCurrentFocus : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private convertInteractionToActivity(interaction: IInteraction) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : convertInteractionToActivity : START'
            );

            const activity: IActivityItem = {
                interactionId: interaction.interactionId,
                direction: interaction.direction,
                details: interaction.details,
                channelType: interaction.channelType,
                state: interaction.state,
                scenarioId: interaction.scenarioId,
                userFocus: false
            };
            this.newActivity = activity;

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : convertInteractionToActivity : END'
            );
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : convertInteractionToActivity : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Checks if there are at least 2 activities
    private areThereOtherActivities() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : areThereOtherActivities : START'
            );

            this.logger.logger.logDebug(
                `Genesys CTI - Service : activity-focus : areThereOtherActivities : RETURN : ${
                    this.currentActivities.length > 1
                }`
            );

            return this.currentActivities.length > 1;
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : areThereOtherActivities : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Returns the first activity that is not the activity
    private findOtherActivity() {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : findOtherActivitys : START'
            );

            if (this.currentActivity !== null && this.currentActivities.length > 0) {
                this.logger.logger.logDebug(
                    'Genesys CTI - Service : activity-focus : findOtherActivity : RETURN : this.currentActivities[0]'
                );
                return this.currentActivities[0];
            } else {
                for (let i = 0; i < this.currentActivities.length; i++) {
                    if (
                        this.currentActivity.interactionId !==
            this.currentActivities[i].interactionId
                    ) {
                        this.logger.logger.logDebug(
                            'Genesys CTI - Service : activity-focus : findOtherActivity : RETURN : this.currentActivities[i]'
                        );
                        return this.currentActivities[i];
                    }
                }
                this.logger.logger.logDebug(
                    'Genesys CTI - Service : activity-focus : findOtherActivity : RETURN : null'
                );
                return null;
            }
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : findOtherActivitys : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    private findUpdateActivity(updateId: string) {
        try {
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : findUpdateActivity : START'
            );

            for (let i = 0; i < this.currentActivities.length; i++) {
                if (this.currentActivities[i].interactionId === updateId) {
                    this.logger.logger.logDebug(
                        'Genesys CTI - Service : activity-focus : findUpdateActivity : RETURN : this.currentActivities[i]'
                    );
                    return this.currentActivities[i];
                }
            }

            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : findUpdateActivity : RETURN : null'
            );
            return null;
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Service : activity-focus : findUpdateActivity : ERROR : ${error.name} : ${error.message}`
            );
        }
    }

    // Extract the unique domain fomr the genesys host URL for formatting the genesys scripts URL
    private extractDomain(url: string) {
        this.logger.logger.logDebug(
            'Genesys CTI - Service : activity-focus : extractDomain - START'
        );
        try {
            const pattern = /.*\/+?(?=.*\.html\?)/g;
            const result = url.match(pattern);
            this.logger.logger.logDebug(
                'Genesys CTI - Service : activity-focus : extractDomain - END'
            );
            return result;
        } catch (error) {
            this.logger.logger.logError(
                `Genesys CTI - Component : Home : extractDomain - Error : ${error.message} `
            );
        }
    }
}
// Basic interface to compare activities
// So far I'm only storing the interactionId and scenarioId of the
// Incoming interactions
interface IActivityItem extends IInteraction {
    userFocus: boolean;
}
